<template>
  <div class="download">
    <h2>APP和网页端同步学习</h2>
    <p>学习记录不丢失，未学习视频跨端播放</p>
    <div class="box">
      <h3>扫码下载APP</h3>
      <img src="../assets/images/appDown.png" />
      <div class="btn">
        <button @click="download_an">Android下载</button>
        <button @click="download">IPhone下载</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "appDownload",
  components: {},
  data() {
    return {};
  },
  mounted() {
    this.$parent.routerIndex = 9;
    this.$parent.footer = true;
  },
  beforeRouteLeave(to, form, next) {
    this.$parent.footer = false;
    next();
  },
  methods: {
    download_an() {
      window.location.href = "https://m.zitisheji.com/字体江湖.apk";
    },
    download() {
      //this.$utile.prompt('success',"IOS版APP正在审核中，即将上线，敬请期待！");
      window.open("https://apps.apple.com/cn/app/字体江湖/id1632147387");
    },
  },
  activated() {
    this.$nextTick(() => {
      this.$parent.routerIndex = 9;
    });
  },
};
</script>

<style scoped>
.download {
  width: 100%;
  height: 837px;
  background: url(../assets/images/app_download.png) no-repeat;
  text-align: center;
  overflow: hidden;
  background-size: cover;
}
.download h2 {
  font-size: 58px;
  margin: 151px auto 26px;
  letter-spacing: 0.5px;
}
.download p {
  font-size: 20px;
  letter-spacing: 0.5px;
}
.box {
  width: 386px;
  height: 373px;
  margin: 62px auto 0;
  border-radius: 10px;
  background: #fff;
  box-shadow: rgb(0 0 0 / 7%) 0px 0px 12px 0px;
  overflow: hidden;
}
.box h3 {
  margin: 54px auto 33px;
  font-size: 21px;
  letter-spacing: 0.5px;
}
.box img {
  display: block;
  margin: 0 auto 34px;
  width: 150px;
  height: 150px;
}
.box .btn {
  margin: auto;
  width: 306px;
}
.box .btn button {
  width: 143px;
  height: 42px;
  border: none;
  background: #5857ff;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 7%) 0px 0px 8px 0px;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  background-size: 18px !important;
  background-position: 20px center !important;
  padding-left: 35px;
  letter-spacing: 0.5px;
}
.box .btn button:first-child {
  float: left;
  background: url(../assets/images/app_an.svg) no-repeat #5857ff;
}
.box .btn button:last-child {
  float: right;
  background: url(../assets/images/app_ios.svg) no-repeat #5857ff;
}
</style>